import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const OcrOnIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg className={classes.svg} viewBox="8 8 16 16" role="presentation" focusable="false">
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M22.9942856,24h-3.8400002v-1.0057144h3.8400002v-3.8400002H24V24H22.9942856z M8,24v-4.8457146h1.0057144
        v3.8400002h3.8400002V24H8z M18.3177147,18.5673141h-0.016367v0.48736h-1.2941704v-6.2089596h1.2942162v2.6457596h0.016367
        c0.3194828-0.4887457,0.7740955-0.733119,1.3638401-0.733119c0.540617,0,0.9570141,0.1856756,1.2491875,0.5570288
        c0.2921753,0.3713512,0.4382458,0.8792057,0.4382172,1.5235653c0,0.6989708-0.1706676,1.2600689-0.512001,1.6832905
        c-0.3413334,0.4232235-0.7973175,0.6348343-1.3679543,0.6348343C18.9730892,19.157074,18.5826435,18.9604874,18.3177147,18.5673141z
        M18.5368233,16.0403214c-0.1706676,0.1938591-0.2560005,0.4464149-0.2560005,0.7576675v0.4300804
        c0,0.2703247,0.0778198,0.4942188,0.2334614,0.6716805c0.1488972,0.1744976,0.368681,0.2723503,0.59799,0.2662392
        c0.294857,0,0.5235348-0.1139946,0.6860332-0.3419876c0.1625004-0.227993,0.2437344-0.5508728,0.2437038-0.9686394
        c0-0.3467598-0.0744095-0.617754-0.2232227-0.8129826c-0.1488171-0.1952305-0.3611736-0.2928467-0.6370735-0.2928486
        c-0.2477551-0.0051069-0.4846191,0.1017122-0.6448002,0.2907887L18.5368233,16.0403214z M14.9592686,19.0546741l-0.4175539-1.306469
        h-2.0888691l-0.4136686,1.306469h-1.4334631l2.1379204-5.8730974h1.568594l2.0887785,5.8730974H14.9592686z M13.3742628,14.7419891
        l-0.6389027,1.9904919h1.5030861l-0.6308575-1.9741259c-0.0511065-0.1721945-0.0840473-0.3492661-0.0982857-0.5283203H13.476572
        c-0.0139942,0.1740484-0.0482845,0.3458662-0.1021719,0.5119534L13.3742628,14.7419891z M22.9942856,12.8457146V9.0057144
        h-3.8400002V8H24v4.8457146H22.9942856z M8,12.8457146V8h4.8457146v1.0057144H9.0057144v3.8400002H8z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M22.9942856,24h-3.8400002v-1.0057144h3.8400002v-3.8400002H24V24H22.9942856z M8,24v-4.8457146h1.0057144
        v3.8400002h3.8400002V24H8z M22.445715,22.445715H9.554286V9.554286H22.445715V22.445715L22.445715,22.445715z
        M18.3014393,18.5673141h0.0163231c0.263588,0.3913612,0.65769,0.5897141,1.171339,0.5897141
        c0.5680027,0,1.0282536-0.2135773,1.3679066-0.6347885c0.3396549-0.4212112,0.5119991-0.9874287,0.5119991-1.6833363
        c0-0.6412811-0.1474285-1.1539202-0.4382648-1.5235662c-0.2908363-0.3696451-0.7110882-0.556982-1.2491398-0.556982
        c-0.5870152,0-1.0459442,0.2468567-1.3638401,0.733119h-0.016367v-2.6457596h-1.29422v6.2089596h1.29422v-0.48736l0,0H18.3014393z
        M14.5415792,17.7481594l0.4177847,1.3065147h1.4416466l-2.0887337-5.8730974H12.743638l-2.1379242,5.8730974h1.4335108
        l0.4136658-1.3065147H14.5415792z M18.7805748,18.0993824c-0.1022034-0.0464191-0.193058-0.1145611-0.2662392-0.1996803
        c-0.0772877-0.0880623-0.1367359-0.1902981-0.1750393-0.3010273c-0.0400333-0.119421-0.0597649-0.2447071-0.0583763-0.3706532
        v-0.4300327c-0.0017567-0.1434917,0.0198612-0.286314,0.0640011-0.4228573
        c0.0410843-0.1232681,0.1063633-0.2370949,0.1919994-0.3348122c0.0797195-0.0925484,0.1783638-0.1669226,0.2892799-0.2181025
        c0.1120758-0.0492716,0.2333755-0.0740528,0.3557911-0.0726862c0.1258774-0.0023499,0.2507706,0.022604,0.3660831,0.073144
        c0.1070137,0.0496225,0.2000446,0.1250181,0.2707653,0.2194281c0.0777225,0.1047382,0.1345749,0.2234516,0.16745,0.3496685
        c0.0390511,0.1512661,0.057806,0.3070545,0.055769,0.4632683c0.0023766,0.186964-0.0180855,0.373518-0.0609379,0.5555191
        c-0.0347157,0.1478863-0.0967293,0.2879925-0.1828556,0.4131203c-0.0750675,0.1084576-0.1758556,0.1966057-0.2933483,0.2565498
        c-0.1224728,0.0586243-0.2569256,0.0878792-0.3926849,0.0854397c-0.1139851,0.0014687-0.2269974-0.0211182-0.3316631-0.0662861
        H18.7805748z M14.238493,16.7325249h-1.5030851l0.6389503-1.9904451c0.0538788-0.1661148,0.0882311-0.3379393,0.102355-0.5120001
        h0.0327787c0.0143194,0.1790581,0.0472603,0.3561373,0.0982866,0.5283661l0.6308594,1.9740353l0,0L14.238493,16.7325249z
        M22.9942856,12.8457146V9.0057144h-3.8400002V8H24v4.8457146H22.9942856z M8,12.8457146V8h4.8457146v1.0057144H9.0057144v3.8400002
        H8z"
      />
    </svg>
  ),
  displayName: 'OcrOnIcon',
});
