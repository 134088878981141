import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const PopupIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="8 8 16 16" className={classes.svg}>
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M11,24A3,3,0,0,1,8,21.05V13.41a3,3,0,0,1,2.46-2.91,2.62,2.62,0,0,1,.18-1.11A2.29,2.29,0,0,1,12.74,8h9A2.27,2.27,0,0,1,24,10.27v9a2.29,2.29,0,0,1-1.39,2.1,2.62,2.62,0,0,1-1.11.18A3,3,0,0,1,18.59,24ZM9.11,13.41v7.64A1.85,1.85,0,0,0,11,22.89h7.64a1.86,1.86,0,0,0,1.78-1.35H12.74a2.28,2.28,0,0,1-2.28-2.28V11.63A1.86,1.86,0,0,0,9.11,13.41Zm2.46-3.14v9a1.17,1.17,0,0,0,1.17,1.17h9a1.16,1.16,0,0,0,1.16-1.17v-9a1.16,1.16,0,0,0-1.16-1.16h-9A1.16,1.16,0,0,0,11.57,10.27Zm2.59,7.09a.56.56,0,0,1,.15-.39l4.12-4.12H16.65a.56.56,0,0,1,0-1.11h3.11a.55.55,0,0,1,.55.55v3.11a.54.54,0,0,1-.55.55.54.54,0,0,1-.55-.55V13.63l-4.12,4.12a.55.55,0,0,1-.39.16A.53.53,0,0,1,14.16,17.36Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M18.59,24H11A3,3,0,0,1,8,21.05V13.41a3,3,0,0,1,2.46-2.91,2.62,2.62,0,0,1,.18-1.11A2.29,2.29,0,0,1,12.74,8h9A2.27,2.27,0,0,1,24,10.27v9a2.29,2.29,0,0,1-1.39,2.1,2.62,2.62,0,0,1-1.11.18A3,3,0,0,1,18.59,24ZM10.46,11.63a1.86,1.86,0,0,0-1.35,1.78v7.64A1.85,1.85,0,0,0,11,22.89h7.64a1.86,1.86,0,0,0,1.78-1.35H12.74a2.28,2.28,0,0,1-2.28-2.28Zm8,1.22L14.31,17a.56.56,0,0,0-.15.39.53.53,0,0,0,.54.55.55.55,0,0,0,.39-.16l4.12-4.12v1.78a.55.55,0,1,0,1.1,0V12.3a.55.55,0,0,0-.55-.55H16.65a.55.55,0,0,0-.55.55.56.56,0,0,0,.55.56Z"
      />
    </svg>
  ),
  displayName: 'PopupIcon',
});
