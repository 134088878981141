import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const YammerIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="0 0 32 32" className={classes.svg}>
      <path
        fill="#28a8ea"
        d="M11.55 9.717a.416.416 0 0 1-.057-.494.41.41 0 0 1 .064-.083L20.085.6a.834.834 0 0 1 1.203.023 22.62 22.62 0 0 1 5.252 9.546L21.378 16l-7.677-2.404a9.259 9.259 0 0 0-2.15-3.88z"
      />
      <path
        fill="#0078d4"
        d="M30.627 9.962a.832.832 0 0 0-1.026-.612L13.7 13.597a9.379 9.379 0 0 1 .003 4.835l5.303 6.607 7.549-3.152 3.042.817a.832.832 0 0 0 1.027-.61 26.868 26.868 0 0 0 .003-12.132z"
      />
      <path
        fill="#0358a7"
        d="M13.703 18.432a9.262 9.262 0 0 1-2.13 3.874.417.417 0 0 0 .006.584l8.525 8.512a.834.834 0 0 0 1.2-.023 22.55 22.55 0 0 0 5.251-9.493z"
      />
      <path
        d="M18 24.667V8.333A1.337 1.337 0 0 0 16.667 7h-2.994l-2.116 2.12a.408.408 0 0 0-.064.082.416.416 0 0 0 .057.494 9.259 9.259 0 0 1 2.151 3.879h.003l-.004.001a9.382 9.382 0 0 1 .003 4.835 9.22 9.22 0 0 1-.415 1.209 9.277 9.277 0 0 1-1.715 2.665.417.417 0 0 0 .006.584L14.715 26h1.952A1.337 1.337 0 0 0 18 24.667z"
        opacity=".1"
      />
      <path
        d="M17 25.667V9.333A1.337 1.337 0 0 0 15.667 8h-2.993l-1.117 1.12a.408.408 0 0 0-.064.082.416.416 0 0 0 .057.494 9.259 9.259 0 0 1 2.151 3.879h.003l-.004.001a9.382 9.382 0 0 1 .003 4.835 9.22 9.22 0 0 1-.415 1.209 9.277 9.277 0 0 1-1.715 2.665.417.417 0 0 0 .006.584l4.137 4.13A1.337 1.337 0 0 0 17 25.667z"
        opacity=".2"
      />
      <path
        d="M17 23.667V9.333A1.337 1.337 0 0 0 15.667 8h-2.993l-1.117 1.12a.408.408 0 0 0-.064.082.416.416 0 0 0 .057.494 9.259 9.259 0 0 1 2.151 3.879h.003l-.004.001a9.382 9.382 0 0 1 .003 4.835 9.22 9.22 0 0 1-.415 1.209 9.277 9.277 0 0 1-1.715 2.665.417.417 0 0 0 .006.584L13.714 25h1.953A1.337 1.337 0 0 0 17 23.667z"
        opacity=".2"
      />
      <path
        d="M16 23.667V9.333A1.337 1.337 0 0 0 14.667 8h-1.993l-1.117 1.12a.408.408 0 0 0-.064.082.416.416 0 0 0 .057.494 9.259 9.259 0 0 1 2.151 3.879h.003l-.004.001a9.382 9.382 0 0 1 .003 4.835 9.22 9.22 0 0 1-.415 1.209 9.277 9.277 0 0 1-1.715 2.665.417.417 0 0 0 .006.584L13.714 25h.953A1.337 1.337 0 0 0 16 23.667z"
        opacity=".2"
      />
      <path
        fill="#0078d4"
        d="M1.333 8h13.334A1.333 1.333 0 0 1 16 9.333v13.334A1.333 1.333 0 0 1 14.667 24H1.333A1.333 1.333 0 0 1 0 22.667V9.333A1.333 1.333 0 0 1 1.333 8z"
      />
      <path
        fill="#fff"
        d="M7.826 15.268a3.361 3.361 0 0 1 .223.6h.021a2.844 2.844 0 0 1 .216-.586l2.023-3.81a.89.89 0 0 1 .785-.472.89.89 0 0 1 .771 1.332l-2.798 4.87a.89.89 0 0 0-.118.444v2.465a.89.89 0 0 1-.89.889h-.18a.89.89 0 0 1-.89-.89v-2.435a.89.89 0 0 0-.113-.434L4.13 12.323A.89.89 0 0 1 4.906 11h.175a.89.89 0 0 1 .79.481z"
      />
      <path fill="none" d="M0 0h32v32H0z" />
    </svg>
  ),
  displayName: 'YammerIcon',
});
