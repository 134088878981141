import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const EmailWithDotIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="8 8 16 16" className={classes.svg}>
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M21.328,10.844A2.676,2.676,0,1,0,24,13.516,2.678,2.678,0,0,0,21.328,10.844Z" />
        <path d="M21.953,19.109a.333.333,0,0,1-.109.274,2.039,2.039,0,0,1-.953.406,30.1,30.1,0,0,1-5.446.438c-1.2,0-5.2-.165-6.148-.672-.2-.1-.367-.2-.367-.446V14.531l5.758,3.438a1.549,1.549,0,0,0,.765.219,1.377,1.377,0,0,0,.735-.219l2.226-1.321a4.511,4.511,0,0,1-.625-.71L15.7,17.188a.456.456,0,0,1-.242.062.566.566,0,0,1-.273-.07L8.93,13.453c0-.32.078-.672.468-.672h7.711a4.713,4.713,0,0,1,.266-.929H9.4A1.427,1.427,0,0,0,8,13.25v5.859a1.19,1.19,0,0,0,.3.844c.922,1.172,5.672,1.2,7.148,1.2,1.453,0,6.25-.039,7.149-1.2a1.2,1.2,0,0,0,.289-.844V17.5a4.748,4.748,0,0,1-.93.242Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M21.328,10.844A2.676,2.676,0,1,0,24,13.516,2.678,2.678,0,0,0,21.328,10.844Z" />
        <path d="M18.43,16.664l-2.25,1.313a1.414,1.414,0,0,1-.727.211,1.573,1.573,0,0,1-.765-.219L8,14.062v5.047a1.24,1.24,0,0,0,.3.844c1.023,1.164,5.609,1.2,7.148,1.2,1.516,0,6.157-.039,7.149-1.2a1.233,1.233,0,0,0,.289-.844V17.5a4.246,4.246,0,0,1-1.555.3A4.3,4.3,0,0,1,18.43,16.664Z" />
        <path d="M15.453,17.25a22.162,22.162,0,0,0,2.367-1.266,4.363,4.363,0,0,1-.781-2.468,4.184,4.184,0,0,1,.336-1.664H9.4a1.405,1.405,0,0,0-1.367,1.14l7.141,4.18A.508.508,0,0,0,15.453,17.25Z" />
      </g>
    </svg>
  ),
  displayName: 'EmailWithDotIcon',
});
