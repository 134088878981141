import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

// TODO: should we reconsider name
export const WorkOrSchoolIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg className={classes.svg} viewBox="8 8 16 16" role="presentation" focusable="false">
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M16.018,18.087h.007a1.835,1.835,0,0,0,1.889-1.881,1.834,1.834,0,0,0-1.889-1.881h-.007a1.881,1.881,0,1,0,0,3.762Zm1.046-1.881a1.043,1.043,0,1,1-1.043-1h0A.98.98,0,0,1,17.064,16.206Z" />
        <path d="M17.993,18.642H14.05a.933.933,0,0,0-.939.927v.124a1.8,1.8,0,0,0,.936,1.577,3.862,3.862,0,0,0,1.974.464A3.863,3.863,0,0,0,18,21.27a1.8,1.8,0,0,0,.936-1.577v-.124A.933.933,0,0,0,17.993,18.642Zm-4.011.928A.068.068,0,0,1,14,19.524a.077.077,0,0,1,.049-.019H18a.067.067,0,0,1,.065.064v.124a.931.931,0,0,1-.514.837,3.048,3.048,0,0,1-1.525.34,3.043,3.043,0,0,1-1.525-.34.93.93,0,0,1-.515-.837Z" />
        <path d="M19.5,10h-.837l1.155-2H18.667l-1.154,2H14.487L13.333,8H12.178l1.155,2H12.5A1.5,1.5,0,0,0,11,11.5V22.5A1.5,1.5,0,0,0,12.5,24H19.5A1.5,1.5,0,0,0,21,22.5V11.5A1.5,1.5,0,0,0,19.5,10ZM20,22.5a.5.5,0,0,1-.5.5H12.5a.5.5,0,0,1-.5-.5V11.5a.5.5,0,0,1,.5-.5H13.91l1.4,2.43a.5.5,0,1,0,.866-.5L15.065,11H19.5a.5.5,0,0,1,.5.5Z" />
      </g>
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M19.5,10h-.837l1.155-2H18.667l-1.154,2H15.646l1.691,2.93a.5.5,0,0,1-.866.5L14.491,10h0L13.333,8H12.178l1.155,2H12.5A1.5,1.5,0,0,0,11,11.5V22.5A1.5,1.5,0,0,0,12.5,24H19.5A1.5,1.5,0,0,0,21,22.5V11.5A1.5,1.5,0,0,0,19.5,10Zm-3.543,4.575a1.633,1.633,0,1,1-1.631,1.631A1.693,1.693,0,0,1,15.961,14.575Zm-2.624,4.994a.708.708,0,0,1,.713-.7h3.943a.708.708,0,0,1,.713.7C18.856,22.194,13.187,22.194,13.337,19.569Z"
      />
    </svg>
  ),
  displayName: 'WorkOrSchoolIcon',
});
