import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const ExcelColorIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="0 0 32 32" className={classes.svg}>
      <path d="M24 15h-3v-2h3m0 5h-3v-2h3m0 5h-3v-2h3m-4-4h-3v-2h3m0 5h-3v-2h3m0 5h-3v-2h3m6.781-10a.96.96 0 0 1 .297.703V29c0 .271-.099.505-.297.703s-.432.297-.703.297h-19a.928.928 0 0 1-.703-.297.96.96 0 0 1-.297-.703v-6H3.172A1.149 1.149 0 0 1 2 21.828V10.172c0-.333.115-.609.344-.828.218-.229.495-.344.828-.344h2.906V3a.96.96 0 0 1 .297-.703A.928.928 0 0 1 7.078 2h12.281c.26 0 .5.099.719.297m0 6.703h5.281l-5.281-5.297M7.297 20l1.547-2.812.125-.203.062-.156L10.703 20h1.875l-2.5-4.031 2.438-4.016h-1.844l-1.578 3.141a12.361 12.361 0 0 0-.25-.547L7.547 12H5.641l2.375 3.984L5.422 20m20.656-10h-6a.928.928 0 0 1-.703-.297.96.96 0 0 1-.297-.703V3h-12v6h7.75c.333 0 .615.115.844.344.219.218.328.495.328.828v11.656c0 .333-.109.615-.328.844a1.175 1.175 0 0 1-.844.328h-7.75v6h19V10z" />
      <path
        fill="#FAFAFA"
        d="M6.5 29V3a.484.484 0 0 1 .5-.5h12.297c.135 0 .25.047.344.141L26.36 9.36c.093.093.14.208.14.343V29a.484.484 0 0 1-.5.5H7a.484.484 0 0 1-.5-.5z"
      />
      <path
        fill="#797774"
        d="M26.703 9a.96.96 0 0 1 .297.703V29c0 .271-.099.505-.297.703S26.271 30 26 30H7c-.271 0-.505-.099-.703-.297S6 29.271 6 29V3c0-.271.099-.505.297-.703S6.729 2 7 2h12.297a.96.96 0 0 1 .703.297M20 9h5.297L20 3.703M26 29V10h-6c-.271 0-.505-.099-.703-.297S19 9.271 19 9V3H7v26h19z"
      />
      <path fill="#21A366" d="M20 15h-3v-2h3v2z" />
      <path fill="#107C41" d="M20 18h-3v-2h3v2z" />
      <path fill="#185C37" d="M20 21h-3v-2h3v2z" />
      <path fill="#33C481" d="M24 15h-3v-2h3v2z" />
      <path fill="#21A366" d="M24 18h-3v-2h3v2z" />
      <path fill="#134A2C" d="M24 21h-3v-2h3v2z" />
      <path
        fill="#107C41"
        d="M14.828 23H3.172A1.149 1.149 0 0 1 2 21.828V10.172c0-.333.115-.609.344-.828.218-.229.495-.344.828-.344h11.656c.333 0 .615.115.844.344.219.218.328.495.328.828v11.656c0 .333-.109.615-.328.844a1.175 1.175 0 0 1-.844.328z"
      />
      <path
        fill="#FFF"
        d="M5.422 20l2.594-4.016L5.641 12h1.906l1.297 2.547c.115.24.198.422.25.547l1.578-3.141h1.844l-2.438 4.016 2.5 4.031h-1.875l-1.672-3.172-.062.156-.125.203L7.297 20H5.422z"
      />
    </svg>
  ),
  displayName: 'ExcelColorIcon',
});
