import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const VideomailIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="8 8 18 16" className={classes.svg}>
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M21,16a.935.935,0,0,0,.312.719L23,18.328V13.672l-1.688,1.609A.935.935,0,0,0,21,16Zm2.5-4a.5.5,0,0,1,.5.5v7a.5.5,0,0,1-.5.5.472.472,0,0,1-.344-.141l-2.531-2.414a1.983,1.983,0,0,1,0-2.89l2.531-2.414A.472.472,0,0,1,23.5,12Zm-8,3.164a.875.875,0,1,0,.875.875A.894.894,0,0,0,15.5,15.164ZM9.82,12c-.8,0-.82,2.906-.82,3.477v1.039c0,.57.023,3.484.82,3.484H17.5a.5.5,0,0,0,.5-.5v-7a.5.5,0,0,0-.5-.5Zm5.68,2.414a1.625,1.625,0,0,1,0,3.25h-4a1.625,1.625,0,1,1,0-3.25,1.622,1.622,0,0,1,1.367,2.5h1.266a1.622,1.622,0,0,1,1.367-2.5Zm-4,.75a.875.875,0,1,0,.875.875A.894.894,0,0,0,11.5,15.164ZM17.5,11A1.531,1.531,0,0,1,19,12.5v7A1.531,1.531,0,0,1,17.5,21H9.82C8.016,21,8,17.891,8,16.648v-1.3C8,14.109,8.016,11,9.82,11Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M23.5,12a.5.5,0,0,1,.5.5v7a.5.5,0,0,1-.5.5.472.472,0,0,1-.344-.141l-2.531-2.414a1.983,1.983,0,0,1,0-2.89l2.531-2.414A.472.472,0,0,1,23.5,12Zm-8,3.164a.875.875,0,1,1-.875.875A.894.894,0,0,1,15.5,15.164Zm0-.75a1.622,1.622,0,0,0-1.367,2.5H12.867a1.624,1.624,0,1,0-1.367.75h4a1.625,1.625,0,0,0,0-3.25Zm-4,.75a.875.875,0,1,1-.875.875A.894.894,0,0,1,11.5,15.164ZM17.5,11A1.531,1.531,0,0,1,19,12.5v7A1.531,1.531,0,0,1,17.5,21H9.82C8.016,21,8,17.891,8,16.648v-1.3C8,14.109,8.016,11,9.82,11Z"
      />
    </svg>
  ),
  displayName: 'VideomailIcon',
});
