import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const CallControlReleaseIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="8 8 16 16" className={classes.svg}>
      <path
        d="M23,11.0816984l0.8432999-0.8432999
     C23.9435902,10.4396133,24,10.6665344,24,10.9066315v6.4375h-0.0038681c-0.030756,0.246645-0.2411556,0.4375-0.4961319,0.4375
     s-0.4653759-0.190855-0.4961319-0.4375H23V11.0816984z M19.5108528,10.4066315H9.5c-0.2761421,0-0.5,0.2238569-0.5,0.5v9
     c0,0.2761421,0.2238579,0.5,0.5,0.5h0.0108538l-0.7850342,0.7850342C8.2908373,20.9290428,8,20.4518013,8,19.9066315v-9
     c0-0.8284273,0.6715727-1.5,1.5-1.5h11.0108528L19.5108528,10.4066315z M16.1707726,13.7467136L14.875,15.0424852v-0.7671566
     c0-0.1745424,0.0626001-0.343524,0.1768417-0.4773626c0.2688255-0.3149405,0.7470818-0.3565264,1.0682163-0.0928869
     L16.1707726,13.7467136z M18.4575882,15.6241102l2.0208569,1.6590528C20.6505604,17.4244633,20.75,17.6332836,20.75,17.8534126
     c0,0.4107227-0.3395042,0.743679-0.7583065,0.743679h-1.3556843l1.8609123,3.6433411
     c0.2237434,0.4380474,0.0456886,0.9710884-0.3989677,1.1943989c-0.0017738,0.0008907-0.0035477,0.0017738-0.0053253,0.0026512
     c-0.4420586,0.2183704-0.9809208,0.0439472-1.2035866-0.3895817l-1.8720703-3.6449432l-0.781147,1.0024796
     c-0.1434631,0.1841164-0.3662395,0.2921276-0.6025181,0.2921276c-0.4188004,0-0.7583065-0.3329563-0.7583065-0.743681v-0.7471848
     L18.4575882,15.6241102z M23.3751297,8.6148157c0.1952629,0.195262,0.1952629,0.5118446,0,0.7071066L9.3213835,23.3756695
     c-0.195262,0.1952629-0.5118446,0.1952629-0.7071066,0s-0.195262-0.5118446,0-0.7071075L22.6680241,8.6148157
     C22.8632851,8.4195538,23.1798687,8.4195538,23.3751297,8.6148157z"
      />
    </svg>
  ),
  displayName: 'CallControlReleaseIcon',
});
