import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const CallControlShareIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="6 6 20 20" className={classes.svg}>
      <path
        d="M24.75,19.0859c0,0.2539-0.0488,0.4941-0.1465,0.7227s-0.2324,0.4277-0.4053,0.6006s-0.373,0.3076-0.6006,0.4053
           s-0.4688,0.1465-0.7227,0.1465H18.5v1.25c0.1299,0,0.3008-0.0098,0.5127-0.0293s0.418-0.0176,0.6201,0.0049
           s0.376,0.0811,0.5225,0.1758s0.2197,0.2529,0.2197,0.4736c0,0.1699-0.0615,0.3164-0.1855,0.4395s-0.2705,0.1855-0.4395,0.1855h-7.5
           c-0.1689,0-0.3154-0.0625-0.4395-0.1855s-0.1855-0.2695-0.1855-0.4395c0-0.2207,0.0732-0.3789,0.2197-0.4736
           s0.3203-0.1533,0.5225-0.1758s0.4082-0.0244,0.6201-0.0049s0.3828,0.0293,0.5127,0.0293v-1.25H9.125
           c-0.2539,0-0.4951-0.0488-0.7227-0.1465s-0.4277-0.2324-0.6006-0.4053s-0.3076-0.3721-0.4053-0.6006S7.25,19.3398,7.25,19.0859
           v-8.75c0-0.2539,0.0488-0.4946,0.1465-0.7227s0.2324-0.4282,0.4053-0.6006s0.373-0.3076,0.6006-0.4053S8.8711,8.4609,9.125,8.4609
           h7.5c0.1689,0,0.3154,0.062,0.4395,0.1855s0.1855,0.27,0.1855,0.4395s-0.0615,0.3159-0.1855,0.4395s-0.2705,0.1855-0.4395,0.1855
           h-7.5c-0.1689,0-0.3154,0.062-0.4395,0.1855S8.5,10.1665,8.5,10.3359v8.75c0,0.1699,0.0615,0.3164,0.1855,0.4395
           s0.2705,0.1855,0.4395,0.1855h13.75c0.1689,0,0.3154-0.0625,0.4395-0.1855S23.5,19.2559,23.5,19.0859v-3.75
           c0-0.1694,0.0615-0.3159,0.1855-0.4395s0.2705-0.1855,0.4395-0.1855s0.3154,0.062,0.4395,0.1855s0.1855,0.27,0.1855,0.4395V19.0859
           z M17.25,22.2109v-1.25h-2.5v1.25H17.25z M24.75,12.2109c0,0.1694-0.0615,0.3159-0.1855,0.4395s-0.2705,0.1855-0.4395,0.1855
           s-0.3154-0.062-0.4395-0.1855S23.5,12.3804,23.5,12.2109V9.3398l-3.9355,3.9355c-0.124,0.1235-0.2705,0.1855-0.4395,0.1855
           s-0.3154-0.062-0.4395-0.1855S18.5,13.0054,18.5,12.8359s0.0615-0.3159,0.1855-0.4395l3.9258-3.9355H19.75
           c-0.1689,0-0.3154-0.062-0.4395-0.1855s-0.1855-0.27-0.1855-0.4395s0.0615-0.3159,0.1855-0.4395s0.2705-0.1855,0.4395-0.1855h4.375
           c0.1689,0,0.3154,0.062,0.4395,0.1855s0.1855,0.27,0.1855,0.4395V12.2109z"
      />
    </svg>
  ),
  displayName: 'CallControlShareIcon',
});
