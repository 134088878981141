import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const ShareGenericIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="8 8 16 16" className={classes.svg}>
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M10.14,24a2.35,2.35,0,0,1-.83-.16,2.12,2.12,0,0,1-.68-.45,2.06,2.06,0,0,1-.46-.67A2,2,0,0,1,8,21.91V13.67a2,2,0,0,1,.17-.81,2.06,2.06,0,0,1,.46-.67,2.12,2.12,0,0,1,.68-.45,2.35,2.35,0,0,1,.83-.16h.75a.55.55,0,0,1,.56.54h0a.55.55,0,0,1-.56.54h-.74a1,1,0,0,0-1,1V21.9a1,1,0,0,0,1,1h8.43a1,1,0,0,0,1.05-1v-.72a.55.55,0,0,1,.55-.54h0a.55.55,0,0,1,.56.54h0v.72a2,2,0,0,1-.17.81,2.06,2.06,0,0,1-.46.67,2.24,2.24,0,0,1-.68.45,2.35,2.35,0,0,1-.83.16ZM11,20.43a.54.54,0,0,1-.09-.35c0-.57,0-1.14,0-1.7A9.89,9.89,0,0,1,11.28,16,5.36,5.36,0,0,1,12,14.51,4.25,4.25,0,0,1,13.65,13a7,7,0,0,1,1.95-.6c.45-.08.91-.12,1.36-.17l.37,0v-.09c0-.83,0-1.67,0-2.5a.84.84,0,0,1,.07-.35.55.55,0,0,1,.88-.13l1.32,1.25,3.67,3.45.53.51a.5.5,0,0,1,.12.65,1.15,1.15,0,0,1-.14.17c-.86.81-1.72,1.62-2.57,2.44l-2.1,2c-.27.27-.55.53-.83.79a.55.55,0,0,1-.88-.12.71.71,0,0,1-.07-.32c0-.9,0-1.79,0-2.69v-.1l-.34,0-.86.1a4.51,4.51,0,0,0-2.45,1.05,9.32,9.32,0,0,0-1.25,1.34l-.55.69a.58.58,0,0,1-.46.22.52.52,0,0,1-.44-.23Zm7.43-3.87a1.27,1.27,0,0,1,0,.28c0,.6,0,1.2,0,1.8v.11l4.21-4-4.22-4v.89c0,.35,0,.71,0,1.06a.51.51,0,0,1-.41.53,1.84,1.84,0,0,1-.33,0,13,13,0,0,0-2,.18,5.51,5.51,0,0,0-1.58.46,3.3,3.3,0,0,0-1.5,1.43,5.27,5.27,0,0,0-.51,1.82c-.05.34-.08.68-.12,1l0,.4h0l0,0,.32-.36a5.27,5.27,0,0,1,1.32-1.12,6.62,6.62,0,0,1,3-.89l1.19,0h0a.49.49,0,0,1,.5.38Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M10.14,24a2.35,2.35,0,0,1-.83-.16,2.12,2.12,0,0,1-.68-.45,2.06,2.06,0,0,1-.46-.67A2,2,0,0,1,8,21.91V13.67a2,2,0,0,1,.17-.81,2.06,2.06,0,0,1,.46-.67,2.12,2.12,0,0,1,.68-.45,2.35,2.35,0,0,1,.83-.16h.75a.55.55,0,0,1,.56.54h0a.55.55,0,0,1-.56.54h-.74a1,1,0,0,0-1,1V21.9a1,1,0,0,0,1,1h8.43a1,1,0,0,0,1.05-1v-.72a.56.56,0,0,1,1.11,0h0v.72a2,2,0,0,1-.17.81,2.06,2.06,0,0,1-.46.67,2.24,2.24,0,0,1-.68.45,2.35,2.35,0,0,1-.83.16ZM11,20.43a.54.54,0,0,1-.09-.35c0-.57,0-1.14,0-1.7A9.89,9.89,0,0,1,11.28,16,5.36,5.36,0,0,1,12,14.51,4.25,4.25,0,0,1,13.65,13a7,7,0,0,1,1.95-.6c.45-.08.91-.12,1.36-.17l.37,0v-.09c0-.83,0-1.67,0-2.5a.84.84,0,0,1,.07-.35.55.55,0,0,1,.88-.13l1.32,1.25,3.67,3.45.53.51a.5.5,0,0,1,.12.65,1.15,1.15,0,0,1-.14.17c-.86.81-1.72,1.62-2.57,2.44l-2.1,2c-.27.27-.55.53-.83.79a.55.55,0,0,1-.88-.12.71.71,0,0,1-.07-.32c0-.9,0-1.79,0-2.69v-.1l-.34,0-.86.1a4.51,4.51,0,0,0-2.45,1.05,9.32,9.32,0,0,0-1.25,1.34l-.55.69a.58.58,0,0,1-.46.22.52.52,0,0,1-.44-.23Z"
      />
    </svg>
  ),
  displayName: 'ShareGenericIcon',
});
