import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const VideoLoadingIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="8 8 18 16" className={classes.svg}>
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M17.5,11H9.82C8.023,11,8,14.07,8,15.312v1.376C8,17.93,8.031,21,9.82,21h7.657c0-.039-.008-.086-.008-.125a6.627,6.627,0,0,1,.07-.883c-.016,0-.023.008-.039.008H9.82C9.031,20,9,17.078,9,16.516V15.477C9,14.914,9.031,12,9.82,12H17.5a.5.5,0,0,1,.5.5v5.883a6.193,6.193,0,0,1,1-1.563V12.5A1.522,1.522,0,0,0,17.5,11ZM23,14.75a5.39,5.39,0,0,1,1-.016V12.5a.5.5,0,0,0-.5-.5.472.472,0,0,0-.344.141l-2.531,2.414a1.963,1.963,0,0,0-.617,1.343,6.371,6.371,0,0,1,1.492-.8l1.5-1.43Zm-.117,3.062a2.485,2.485,0,0,1,1.633.555c-.25,0-1.008-.133-1.008.344a.362.362,0,0,0,.344.336h1.57a.349.349,0,0,0,.336-.336V17.133a.349.349,0,0,0-.336-.336c-.422,0-.352.594-.336.875a3.369,3.369,0,0,0-5.508,2.609.467.467,0,0,0,.445.453.462.462,0,0,0,.415-.281c.085-.2.054-.578.124-.828A2.5,2.5,0,0,1,22.883,17.812ZM25.9,19.836a.462.462,0,0,0-.437.273c-.078.18-.055.579-.125.821a2.5,2.5,0,0,1-2.391,1.828,2.555,2.555,0,0,1-1.57-.563c.234,0,1.016.133,1.016-.336a.37.37,0,0,0-.344-.343h-1.57a.356.356,0,0,0-.336.343V23.43a.348.348,0,0,0,.359.336c.4,0,.328-.625.312-.875a3.37,3.37,0,0,0,5.508-2.61A.457.457,0,0,0,25.9,19.836Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M17.5,11H9.82C8.016,11,8,14.109,8,15.352v1.3C8,17.883,8.023,21,9.82,21h7.657c0-.039-.008-.086-.008-.125A6.16,6.16,0,0,1,19,16.82V12.5A1.522,1.522,0,0,0,17.5,11ZM24,12.5a.5.5,0,0,0-.5-.5.472.472,0,0,0-.344.141l-2.531,2.414a1.948,1.948,0,0,0-.617,1.343A6.093,6.093,0,0,1,24,14.734Zm-1.117,5.312a2.485,2.485,0,0,1,1.633.555c-.25,0-1.008-.133-1.008.344a.362.362,0,0,0,.344.336h1.57a.349.349,0,0,0,.336-.336V17.133a.349.349,0,0,0-.336-.336c-.422,0-.352.594-.336.875a3.369,3.369,0,0,0-5.508,2.609.467.467,0,0,0,.445.453.462.462,0,0,0,.415-.281c.085-.2.054-.578.124-.828A2.5,2.5,0,0,1,22.883,17.812ZM25.9,19.836a.462.462,0,0,0-.437.273c-.078.18-.055.579-.125.821a2.5,2.5,0,0,1-2.391,1.828,2.555,2.555,0,0,1-1.57-.563c.234,0,1.016.133,1.016-.336a.37.37,0,0,0-.344-.343h-1.57a.356.356,0,0,0-.336.343V23.43a.348.348,0,0,0,.359.336c.4,0,.328-.625.312-.875a3.37,3.37,0,0,0,5.508-2.61A.457.457,0,0,0,25.9,19.836Z"
      />
    </svg>
  ),
  displayName: 'VideoLoadingIcon',
});
