import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const ThumbtackSlashIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="8 8 16 16" className={classes.svg}>
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M23.499979,8c0.1354237,0,0.252615,0.0494776,0.3515778,0.1484318S24,8.3645687,24,8.49998
          s-0.0494804,0.252594-0.1484432,0.3515482l-5.2189636,5.2263498l0.0703163,0.0624971
          c0.1614647-0.0937462,0.3281384-0.1861906,0.4974155-0.2773323c0.1692791-0.0911427,0.3411598-0.1718683,0.515646-0.242178
          s0.3541813-0.126297,0.5390854-0.1679621c0.1849022-0.0416651,0.3724098-0.0624971,0.5651264-0.0624971
          c0.1875076,0,0.3593903,0.031249,0.515646,0.0937462s0.2890739,0.1484318,0.3984547,0.257802
          c0.1093788,0.1093702,0.1953201,0.242178,0.257822,0.3984213s0.0937538,0.3281116,0.0937538,0.515604
          c0,0.2447815-0.036459,0.4973755-0.1119843,0.757782c-0.0755234,0.2604055-0.1770897,0.520812-0.3046989,0.7812176
          c-0.1276093,0.2610569-0.2734489,0.5175571-0.4375172,0.7701511c-0.1640701,0.252594-0.3385563,0.4960728-0.5208549,0.7304382
          s-0.3645992,0.4570141-0.5468979,0.667942c-0.1822987,0.2109299-0.3541813,0.4023285-0.515646,0.5741959l3.7032757,3.7107868
          c0.0989628,0.0989532,0.1484432,0.2161369,0.1484432,0.3515472c0,0.1354122-0.0494804,0.252594-0.1484432,0.3515491
          c-0.0989628,0.0989532-0.2161541,0.1484318-0.3515759,0.1484318c-0.1354237,0-0.252615-0.0494785-0.3515778-0.1484318
          l-3.7032757-3.7029743c-0.1718826,0.1614513-0.3619938,0.3333187-0.5729389,0.515604
          c-0.210947,0.1822834-0.4349136,0.3645687-0.6692982,0.5468521c-0.2343845,0.1822853-0.479187,0.3554554-0.7344055,0.5195103
          c-0.2552185,0.1640568-0.5130424,0.3098831-0.7734699,0.4374828c-0.2604265,0.1275978-0.520854,0.2291565-0.7812805,0.3046741
          c-0.2604275,0.0755177-0.5130424,0.1132774-0.757844,0.1132774c-0.1875076,0-0.3593893-0.03125-0.515646-0.0937462
          c-0.1562557-0.0624981-0.2890739-0.1484318-0.3984537-0.257803c-0.1093788-0.1093693-0.1953201-0.242178-0.257823-0.3984203
          c-0.0625019-0.1562443-0.0937538-0.3281116-0.0937538-0.515604c0-0.1927013,0.0234385-0.3814945,0.0677118-0.5663834
          c0.0442724-0.1848888,0.1015663-0.3645687,0.1718817-0.5390415c0.0703154-0.1744709,0.1484432-0.3463402,0.236989-0.515604
          c0.0885448-0.1692638,0.1796942-0.3346214,0.273448-0.4960728l-0.0625019-0.0781212l-5.2261248,5.2263489
          C8.752635,23.9505234,8.6354427,24,8.50002,24c-0.1354218,0-0.252614-0.0494766-0.3515768-0.1484318
          C8.0494814,23.752615,8,23.6354313,8,23.500021c0-0.1354122,0.0494814-0.252594,0.1484432-0.3515491L23.1484032,8.1484318
          C23.247366,8.0494776,23.3645573,8,23.499979,8z M15.0777617,8.49998c0.2031336,0,0.3880367,0.0312481,0.5547104,0.0937462
          c0.1666737,0.0624971,0.3099089,0.1523371,0.4271002,0.2695198c0.1171932,0.1171827,0.2057381,0.2591038,0.26824,0.4257641
          c0.0625038,0.1666603,0.0937538,0.3515482,0.0937538,0.5546646c0,0.3281116-0.0572929,0.647109-0.1718807,0.9569931
          c-0.1145878,0.3098831-0.2500105,0.605444-0.4062672,0.8866825l1.2813015,1.1015177l-0.7109661,0.7109079l-1.8672628-1.6014967
          c0.0937538-0.1458273,0.1927156-0.3007689,0.2968864-0.4648247s0.2005291-0.3346224,0.2864704-0.5116987
          c0.0859413-0.1770754,0.1562567-0.3567562,0.2135506-0.5390406s0.0859404-0.3619642,0.0859404-0.5390406
          c0-0.2343655-0.1171923-0.3515482-0.3515768-0.3515482c-0.2291756,0-0.4817905,0.0546856-0.7604475,0.1640558
          c-0.278657,0.1093712-0.5703354,0.2604065-0.8750353,0.4531069s-0.6139574,0.4166498-0.9264698,0.6718473
          c-0.3125134,0.2551985-0.6146088,0.5286245-0.9062872,0.8202791c-0.2916784,0.2916555-0.5664291,0.5950279-0.8242521,0.91012
          c-0.257823,0.3150911-0.4817905,0.6236725-0.6719027,0.9257431S9.77351,14.0310049,9.6641302,14.3122435
          S9.500061,14.8486795,9.500061,15.077837c0,0.1249952,0.0260429,0.2148352,0.0781279,0.2695208
          c0.0520859,0.0546846,0.1406307,0.0820274,0.2656364,0.0820274c0.1718817,0,0.3502741-0.0286446,0.5351782-0.0859337
          c0.1849031-0.0572901,0.3659-0.1289015,0.5429907-0.2148352s0.3489723-0.1809826,0.515646-0.2851448
          c0.1666727-0.1041622,0.3203249-0.2031164,0.4609556-0.2968626l1.6009769,1.8677626l-0.7103157,0.7109089l-1.1016073-1.2818499
          c-0.2812614,0.1562443-0.5768461,0.2916555-0.886754,0.4068842c-0.3099089,0.1145802-0.628932,0.1718693-0.9570704,0.1718693
          c-0.1979246,0-0.3789215-0.0325508-0.5429907-0.0976524s-0.3060026-0.1581974-0.4257994-0.2779846
          c-0.1197958-0.1197863-0.2122478-0.2617073-0.2773542-0.4257631C8.5325737,15.456728,8.50002,15.2757454,8.50002,15.077837
          c0-0.2968626,0.0612011-0.6197662,0.1836014-0.9687109c0.1224012-0.3489437,0.2916784-0.7056999,0.5078335-1.0702686
          c0.2161541-0.3645687,0.4700708-0.7291374,0.7617493-1.0937052c0.2916784-0.3645687,0.6054935-0.7148151,0.9414454-1.0507383
          c0.3359509-0.3359241,0.6862249-0.6497135,1.0508232-0.9413681c0.3645983-0.2916555,0.7291965-0.5455513,1.0931435-0.7616882
          c0.3645983-0.2161369,0.721384-0.3854008,1.0703564-0.5077915S14.7808752,8.49998,15.0777617,8.49998z M20.8201828,14.3825531
          c-0.1822987,0-0.3724098,0.0325508-0.5703354,0.0976524c-0.1979256,0.0651007-0.3932457,0.1484308-0.5859623,0.2499895
          c-0.1927147,0.1015587-0.3776188,0.2096272-0.5573139,0.3242054s-0.3437634,0.2239494-0.489603,0.3281116l-0.6953411-0.5937262
          l-3.1329393,3.133337l0.5937738,0.6952839c-0.0937538,0.1458263-0.1979246,0.3085804-0.3151169,0.4882603
          c-0.1171923,0.1796818-0.2265711,0.3671741-0.3281384,0.5624771c-0.1015663,0.1953049-0.1875076,0.3919125-0.2552185,0.5898209
          s-0.1015663,0.3854008-0.1015663,0.5624771s0.0911493,0.2656136,0.273448,0.2656136
          c0.2395935,0,0.515646-0.0664024,0.8255548-0.1992111c0.3099079-0.1328068,0.6380463-0.3124866,0.9844151-0.5390396
          s0.7005501-0.4908657,1.0651474-0.7929363s0.7187786-0.6236725,1.0599384-0.9648056
          c0.3411598-0.3411312,0.6614857-0.6939812,0.9635811-1.0585499s0.5677319-0.7200222,0.7943039-1.0663624
          c0.226572-0.3469925,0.4062653-0.6751032,0.5390835-0.9849863s0.1979256-0.5846119,0.1979256-0.8241854
          C21.0858192,14.4736948,20.9972744,14.3825531,20.8201828,14.3825531z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M23.499979,8c0.1354237,0,0.252615,0.0494776,0.3515778,0.1484318S24,8.3645687,24,8.49998
          s-0.0494804,0.252594-0.1484432,0.3515482l-5.2189636,5.2263498l0.0703163,0.0624971
          c0.1666737-0.0989542,0.3333473-0.1927004,0.500021-0.2812386s0.3359509-0.1679621,0.5078316-0.2382717
          c0.1718826-0.0703096,0.3515778-0.126297,0.5364819-0.1679621c0.1849022-0.0416651,0.3750153-0.0624971,0.5729389-0.0624971
          c0.3802242,0,0.6875286,0.115881,0.9193077,0.3476419c0.231781,0.2317619,0.3463688,0.5377388,0.3463688,0.9179316
          c0,0.2395735-0.036459,0.4882612-0.1119843,0.7460632c-0.0755234,0.257802-0.1770897,0.5169058-0.3046989,0.7773132
          c-0.1276093,0.2610569-0.2760525,0.518858-0.4427261,0.7740555c-0.1666737,0.2551994-0.3411598,0.5012817-0.5234585,0.7382526
          c-0.1823006,0.236969-0.3645992,0.4609184-0.5468979,0.6718464c-0.1822987,0.2109299-0.3515778,0.4023285-0.5078335,0.5741959
          l3.7032757,3.7107868c0.0989628,0.0989532,0.1484432,0.2161369,0.1484432,0.3515472
          c0,0.1354122-0.0494804,0.252594-0.1484432,0.3515491c-0.0989628,0.0989532-0.2161541,0.1484318-0.3515759,0.1484318
          c-0.1354237,0-0.252615-0.0494785-0.3515778-0.1484318l-3.7032757-3.7029743
          c-0.1666737,0.1562424-0.3567848,0.3255081-0.5703354,0.5077915s-0.4375172,0.363266-0.6745071,0.5429459
          c-0.2369881,0.1796818-0.4843941,0.3528519-0.742218,0.5195103c-0.257822,0.1666603-0.5182495,0.3137894-0.778677,0.4413891
          c-0.2604265,0.1275997-0.5182495,0.2304592-0.7760725,0.3085804c-0.257823,0.0781231-0.5078335,0.1171837-0.747427,0.1171837
          c-0.1875076,0-0.3593893-0.03125-0.515646-0.0937462c-0.1562557-0.0624981-0.2890739-0.1484318-0.3984537-0.257803
          c-0.1093788-0.1093693-0.1953201-0.242178-0.257823-0.3984203c-0.0625019-0.1562443-0.0937538-0.3281116-0.0937538-0.515604
          c0-0.1979084,0.0234385-0.3880062,0.0677118-0.5702896c0.0442724-0.1822834,0.1015663-0.3606625,0.1718817-0.5351353
          c0.0703154-0.1744709,0.1484432-0.3450375,0.236989-0.5116978c0.0885448-0.1666584,0.1796942-0.3333187,0.273448-0.499979
          l-0.0625019-0.0781212l-5.2261248,5.2263489C8.752635,23.9505234,8.6354427,24,8.50002,24
          c-0.1354218,0-0.252614-0.0494766-0.3515768-0.1484318C8.0494814,23.752615,8,23.6354313,8,23.500021
          c0-0.1354122,0.0494814-0.252594,0.1484432-0.3515491L23.1484032,8.1484318C23.247366,8.0494776,23.3645573,8,23.499979,8z
          M15.0777617,8.49998c0.4062672,0,0.731801,0.121089,0.9766016,0.363266
          c0.2448025,0.242178,0.3672028,0.5689878,0.3672028,0.9804287c0,0.3281116-0.0572929,0.647109-0.1718807,0.9569931
          c-0.1145878,0.3098831-0.2500105,0.605444-0.4062672,0.8866825l1.2813015,1.1015177l-4.3354626,4.3364124l-1.1016073-1.2818499
          c-0.2812614,0.1562443-0.5768461,0.2916555-0.886754,0.4068842c-0.3099089,0.1145802-0.628932,0.1718693-0.9570704,0.1718693
          c-0.2031336,0-0.386735-0.0325508-0.5508041-0.0976524s-0.3046999-0.1568947-0.4218922-0.2740784
          c-0.1171923-0.1171818-0.2083416-0.2578011-0.273448-0.4218569C8.5325737,15.4645405,8.50002,15.2809534,8.50002,15.077837
          c0-0.2968626,0.0612011-0.6184645,0.1836014-0.9648046c0.1224012-0.3463402,0.2916784-0.7017946,0.5078335-1.0663624
          c0.2161541-0.3645687,0.4700708-0.7304392,0.7617493-1.0976114c0.2916784-0.3671732,0.6054935-0.7187214,0.9414454-1.0546446
          c0.3359509-0.3359241,0.6862249-0.6497135,1.0508232-0.9413681c0.3645983-0.2916555,0.7291965-0.5455513,1.0931435-0.7616882
          c0.3645983-0.2161369,0.721384-0.3854008,1.0703564-0.5077915S14.7808752,8.49998,15.0777617,8.49998z"
      />
    </svg>
  ),
  displayName: 'ThumbtackSlashIcon',
});
