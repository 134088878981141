import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

// TODO: should we reconsider name
export const TenantPersonalIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg className={classes.svg} viewBox="8 8 16 16" role="presentation" focusable="false">
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M21.633,15.711a2.465,2.465,0,1,1-2.469-2.469A2.484,2.484,0,0,1,21.633,15.711Zm-2.461-1.586a1.59,1.59,0,1,0,1.586,1.586A1.587,1.587,0,0,0,19.172,14.125Zm4.312,5.2c.477,0,.516.3.516.688a3.3,3.3,0,0,1-.023.476c-.25,1.789-3.321,2.258-4.75,2.258-1.5,0-4.891-.484-4.891-2.554,0-.5-.055-.868.578-.868Zm-8.257.891a1.053,1.053,0,0,0,.4.727,6.169,6.169,0,0,0,3.477.921c1.093,0,3.351-.211,3.921-1.3a.621.621,0,0,0,.086-.282v-.062ZM18.2,12.672l-.851.414c-.836-.688-1.633-1.43-2.438-2.156-.273-.242-.547-.524-.836-.742a.422.422,0,0,0-.258-.063.5.5,0,0,0-.32.125c-.773.664-1.531,1.359-2.3,2.039-.719.641-1.461,1.273-2.172,1.93a.437.437,0,0,0-.156.351v5.172a.4.4,0,0,0,.187.352,5.921,5.921,0,0,0,2.321.445c.094,0,.281,0,.312-.109.11-.422.039-3.055.039-3.735a1.241,1.241,0,0,1,.891-1.336,4.549,4.549,0,0,1,1.219-.07c.258,0,.523,0,.789.008a1.269,1.269,0,0,1,1.273,1.015,4.8,4.8,0,0,1,.024.75c0,.461-.016.915-.016,1.376,0,.062-.016.085-.078.085-.117.008-.234-.007-.352-.007s-.437.062-.437-.078V16.594a.4.4,0,0,0-.453-.422H13.047a.394.394,0,0,0-.422.289.7.7,0,0,0-.008.133c0,1.164-.008,2.336-.008,3.5,0,.828-.359,1.32-1.226,1.32a9.647,9.647,0,0,1-1.336-.148C9.266,21.156,8,20.93,8,19.9V14.477a1.1,1.1,0,0,1,.3-.743,15.437,15.437,0,0,1,1.289-1.187c1.133-.992,2.281-1.977,3.39-3a1.21,1.21,0,0,1,.836-.3,1.686,1.686,0,0,1,.438.055,1.135,1.135,0,0,1,.43.25c.734.625,1.453,1.289,2.171,1.929C17.3,11.875,17.734,12.32,18.2,12.672Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M16.7,15.7a2.465,2.465,0,1,1,2.508,2.485A2.492,2.492,0,0,1,16.7,15.7Zm6.774,3.633c.539,0,.523.391.523.8,0,2.2-3.32,2.609-4.961,2.609-1.375,0-3.8-.422-4.508-1.781a2.257,2.257,0,0,1-.187-1.18.454.454,0,0,1,.492-.453ZM18.2,12.687a3.19,3.19,0,0,0-.586,5.829c-.023,0-.047.007-.07.007H15.172c-.1,0-.117-.031-.117-.125V16.641a.416.416,0,0,0-.461-.461H13.031a.406.406,0,0,0-.414.406c0,.562.008,1.133.008,1.7s-.008,1.141-.008,1.7c0,.836-.258,1.43-1.187,1.43a6.125,6.125,0,0,1-.93-.086C9.617,21.2,8,21.109,8,19.867V14.531a1.36,1.36,0,0,1,.531-1.023c1.485-1.352,3-2.7,4.524-4.008a1.2,1.2,0,0,1,.828-.234,1.078,1.078,0,0,1,.7.218c.648.5,1.414,1.235,2.039,1.8C17.148,11.75,17.687,12.211,18.2,12.687Z"
      />
    </svg>
  ),
  displayName: 'TenantPersonalIcon',
});
