import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const VisioColorIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="0 0 32 32" className={classes.svg}>
      <path
        d="M22,11.5 L20,13.5 L20,18 L17,18 L17,20 L15,20 L15,15 L17,15 L17,17 L19,17 L19,13.5 L17,11.5 L19.5,9 L22,11.5 Z M24.703125,7 C24.9010417,7.19791667 25,7.43229167 25,7.703125 L25,27 C25,27.2708333 24.9010417,27.5052083 24.703125,27.703125 C24.5052083,27.9010417 24.2708333,28 24,28 L5,28 C4.72916667,28 4.49479167,27.9010417 4.296875,27.703125 C4.09895833,27.5052083 4,27.2708333 4,27 L4,21 L1.171875,21 C0.838541667,21 0.5625,20.890625 0.34375,20.671875 C0.114583333,20.4427083 0,20.1614583 0,19.828125 L0,8.171875 C0,7.83854167 0.114583333,7.5625 0.34375,7.34375 C0.5625,7.11458333 0.838541667,7 1.171875,7 L4,7 L4,1 C4,0.729166667 4.09895833,0.494791667 4.296875,0.296875 C4.49479167,0.0989583333 4.72916667,0 5,0 L17.296875,0 C17.5677083,0 17.8020833,0.0989583333 18,0.296875 L24.703125,7 Z M18,7 L23.296875,7 L18,1.703125 L18,7 Z M6.09375,18 L7.859375,18 L10.890625,10 L9.25,10 L7.453125,15.203125 C7.203125,15.9114583 7.0625,16.359375 7.03125,16.546875 L7,16.546875 C6.97916667,16.4635417 6.9375,16.3177083 6.875,16.109375 C6.80208333,15.9010417 6.70833333,15.6302083 6.59375,15.296875 L4.796875,10 L3.109375,10 L6.09375,18 Z M24,27 L24,8 L18,8 C17.7291667,8 17.4947917,7.90104167 17.296875,7.703125 C17.0989583,7.50520833 17,7.27083333 17,7 L17,1 L5,1 L5,7 L12.828125,7 C13.1614583,7 13.4427083,7.11458333 13.671875,7.34375 C13.890625,7.5625 14,7.83854167 14,8.171875 L14,19.828125 C14,20.1614583 13.890625,20.4427083 13.671875,20.671875 C13.4427083,20.890625 13.1614583,21 12.828125,21 L5,21 L5,27 L24,27 Z"
        fill="#000000"
      />
      <path
        d="M4.5,27 L4.5,1 C4.5,0.854166667 4.546875,0.734375 4.640625,0.640625 C4.734375,0.546875 4.85416667,0.5 5,0.5 L17.296875,0.5 C17.4322917,0.5 17.546875,0.546875 17.640625,0.640625 L24.359375,7.359375 C24.453125,7.453125 24.5,7.56770833 24.5,7.703125 L24.5,27 C24.5,27.1458333 24.453125,27.265625 24.359375,27.359375 C24.265625,27.453125 24.1458333,27.5 24,27.5 L5,27.5 C4.85416667,27.5 4.734375,27.453125 4.640625,27.359375 C4.546875,27.265625 4.5,27.1458333 4.5,27 Z"
        fill="#FAFAFA"
      />
      <path
        d="M24.703125,7 C24.9010417,7.19791667 25,7.43229167 25,7.703125 L25,27 C25,27.2708333 24.9010417,27.5052083 24.703125,27.703125 C24.5052083,27.9010417 24.2708333,28 24,28 L5,28 C4.72916667,28 4.49479167,27.9010417 4.296875,27.703125 C4.09895833,27.5052083 4,27.2708333 4,27 L4,1 C4,0.729166667 4.09895833,0.494791667 4.296875,0.296875 C4.49479167,0.0989583333 4.72916667,0 5,0 L17.296875,0 C17.5677083,0 17.8020833,0.0989583333 18,0.296875 L24.703125,7 Z M18,7 L23.296875,7 L18,1.703125 L18,7 Z M24,27 L24,8 L18,8 C17.7291667,8 17.4947917,7.90104167 17.296875,7.703125 C17.0989583,7.50520833 17,7.27083333 17,7 L17,1 L5,1 L5,27 L24,27 Z"
        fill="#797774"
      />
      <polygon fill="#185ABD" points="20 13 20 18 16 18 16 17 19 17 19 13" />
      <polygon fill="#41A5EE" points="17 20 15 20 15 15 17 15" />
      <polygon fill="#103F91" points="22 11.5 19.5 14 17 11.5 19.5 9" />
      <path
        d="M12.828125,21 L1.171875,21 C0.838541667,21 0.5625,20.890625 0.34375,20.671875 C0.114583333,20.4427083 0,20.1614583 0,19.828125 L0,8.171875 C0,7.83854167 0.114583333,7.5625 0.34375,7.34375 C0.5625,7.11458333 0.838541667,7 1.171875,7 L12.828125,7 C13.1614583,7 13.4427083,7.11458333 13.671875,7.34375 C13.890625,7.5625 14,7.83854167 14,8.171875 L14,19.828125 C14,20.1614583 13.890625,20.4427083 13.671875,20.671875 C13.4427083,20.890625 13.1614583,21 12.828125,21 Z"
        fill="#185ABD"
      />
      <path
        d="M3.109375,10 L4.796875,10 L6.59375,15.296875 C6.70833333,15.6302083 6.80208333,15.9010417 6.875,16.109375 C6.9375,16.3177083 6.97916667,16.4635417 7,16.546875 L7.03125,16.546875 C7.0625,16.359375 7.203125,15.9114583 7.453125,15.203125 L9.25,10 L10.890625,10 L7.859375,18 L6.09375,18 L3.109375,10 Z"
        fill="#FFFFFF"
      />
    </svg>
  ),
  displayName: 'VisioColorIcon',
});
