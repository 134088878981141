import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const CustomerHubIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="8 8 16 16" className={classes.svg}>
      <path
        d="M16.6677189,19.2568913c0,1.2613659-0.0001125,2.5227318,0.0001278,3.7840977
             c0.0000324,0.175972-0.0252991,0.3450794-0.1545162,0.4765549c-0.2148132,0.2185688-0.5815964,0.3452873-0.9190292,0.0100632
             c-0.2231178-0.2216587-0.4282894-0.4615517-0.6400566-0.6945972c-0.2325993-0.2559719-0.4650173-0.5121384-0.6945219-0.7708893
             c-0.0984001-0.110939-0.2106886-0.1609554-0.3632355-0.1494064c-0.826623,0.0625744-1.654871,0.0872154-2.4831448,0.0512466
             c-0.7625599-0.0331116-1.5253496-0.077652-2.269804-0.2680779c-0.2972498-0.0760326-0.5879869-0.1709232-0.8315334-0.3692398
             c-0.1886692-0.1536293-0.2897701-0.3541737-0.3065205-0.597023c-0.00387-0.0561161-0.0050573-0.1125221-0.0050364-0.1687927
             c0.0006475-1.7090378,0.0067139-3.4180965-0.0004377-5.127099c-0.0023103-0.5520077,0.3899126-0.959136,0.9456787-0.9696817
             c0.3537464-0.006712,0.707634-0.0083666,1.0614548-0.008173c1.8843403,0.0010319,3.7686796,0.0044613,5.653019,0.0042486
             c0.3447561-0.0000381,0.6337061,0.1080828,0.8377352,0.3973465c0.129528,0.1836367,0.1720161,0.394186,0.1720314,0.6154137
             c0.0000534,0.8700056,0.000021,1.7400103,0.000021,2.6100149c0,0.3913326,0,0.7826653,0,1.1739979
             C16.6692066,19.2568951,16.6684628,19.2568932,16.6677189,19.2568913z M10.3305349,17.3390961c1.3367119,0,2.6683969,0,4.0022993,0
             c0-0.1839809,0-0.3620682,0-0.5395718c-1.3383303,0-2.6700888,0-4.0022993,0
             C10.3305349,16.9825039,10.3305349,17.1589241,10.3305349,17.3390961z M12.989069,18.5289936c-0.893404,0-1.7760944,0-2.6603546,0
             c0,0.185585,0,0.3642616,0,0.5434475c0.8893938,0,1.7727737,0,2.6603546,0
             C12.989069,18.8894329,12.989069,18.7109203,12.989069,18.5289936z"
      />
      <path
        d="M14.9864664,12.7905407c-0.217145,0-0.4241648,0-0.6438694,0c-0.0079136-0.0971928-0.0188684-0.1938295-0.0232-0.2907648
		         c-0.0448275-1.0030584,0.1615381-1.9358006,0.7816372-2.7476082c0.6001034-0.7856302,1.3862705-1.2696247,2.3640652-1.4216852
		         c0.8379307-0.1303091,1.6634064-0.0738811,2.4392891,0.28899c1.0458794,0.4891453,1.7219181,1.2855358,1.9069538,2.4531374
		         c0.0979939,0.6183634,0.0697899,1.2309494-0.1563358,1.8206873c-0.1839161,0.4796591-0.4750404,0.8823481-0.9289665,1.1412926
		         c-0.3627567,0.2069359-0.7536335,0.2479057-1.1561642,0.138176c-0.3370228-0.0918732-0.5694122-0.4077539-0.6077785-0.8116331
		         c-0.0076256-0.0802727-0.0138035-0.1606836-0.0362873-0.2466278c-0.0107841,0.0287333-0.0219994,0.057313-0.0322857,0.0862236
		         c-0.1041794,0.2927942-0.2466412,0.5589123-0.4931755,0.7606878c-0.5258121,0.4303513-1.64007,0.4211178-2.0661945-0.559782
		         c-0.1469154-0.3381891-0.183073-0.6938934-0.1692371-1.0572367c0.017662-0.4638071,0.1074619-0.9104099,0.3377037-1.3190823
		         c0.2269764-0.4028807,0.5289536-0.7269115,0.973877-0.8902969c0.2985306-0.1096268,0.6074238-0.1492538,0.9184875-0.0761108
		         c0.3117447,0.0733032,0.5466595,0.2529469,0.6787815,0.5633612c0.0168648-0.1756201,0.0337276-0.3512402,0.0506744-0.5277023
		         c0.1967163,0,0.383831,0,0.5817013,0c-0.0146198,0.1778059-0.0285606,0.3506498-0.0430908,0.5234442
		         c-0.0481052,0.5720997-0.0955105,1.1442623-0.1450691,1.7162361c-0.0226078,0.2609282-0.0507946,0.5215282,0.0000496,0.7822027
		         c0.0096684,0.0495691,0.0205002,0.0990973,0.0343037,0.1476402c0.1144314,0.4024,0.4104443,0.5007191,0.7752724,0.3749409
		         c0.2518749-0.0868378,0.4195175-0.2752733,0.553093-0.4965324c0.1866093-0.3091078,0.2779179-0.6493979,0.3134804-1.0060043
		         c0.0543442-0.5449677,0.0139771-1.0803013-0.1886005-1.5937176c-0.2761879-0.699976-0.7652016-1.1948957-1.4570808-1.4863148
		         c-0.5262222-0.2216454-1.0783749-0.2760639-1.6421852-0.2418137c-0.5307178,0.0322399-1.0289803,0.1756287-1.4720192,0.4755306
		         c-0.7468777,0.5055723-1.2089968,1.2077732-1.3979015,2.0897188c-0.0938997,0.4383898-0.1084776,0.8814249-0.0553379,1.3266745
		         C14.9840307,12.7314939,14.9843626,12.756732,14.9864664,12.7905407z M18.9632778,11.6473684
		         c-0.0118713-0.1342974-0.0169411-0.2696238-0.0369434-0.4026928c-0.0512867-0.3412008-0.258831-0.5843306-0.5445595-0.6490316
		         c-0.4629135-0.1048231-0.8992004,0.0585461-1.1623802,0.4544563c-0.3914585,0.5888796-0.498127,1.2429037-0.3407459,1.9220991
		         c0.1990414,0.858984,1.0591621,0.8442745,1.4697571,0.4649239c0.1384315-0.1278982,0.2447128-0.2772255,0.3271523-0.4455957
		         C18.8831444,12.5675507,18.9442558,12.1132374,18.9632778,11.6473684z"
      />
      <path
        d="M17.701746,22.5662632c0-0.201313,0-0.3941135,0-0.5975761c0.0384445,0,0.0740395,0.0000134,0.1096344-0.0000038
		         c1.7329407-0.0007648,3.4658833-0.0015125,5.1988239-0.0023212c0.2600117-0.0001202,0.3961201-0.1251907,0.3965759-0.3840847
		         c0.00243-1.3798084,0.0012131-2.7596226,0.0011311-4.1394348c0-0.0055084-0.0018101-0.0110168-0.0051079-0.0298557
		         c-0.0335693,0.0160923-0.0654926,0.0282974-0.0943203,0.0456676c-0.6348572,0.3825493-1.2699299,0.7647457-1.9037971,1.148941
		         c-0.6626835,0.4016628-1.3246517,0.8045216-1.9854603,1.2092743c-0.0772171,0.0472965-0.1424007,0.0464706-0.2183819,0.0004921
		         c-0.4827938-0.2921505-0.9671841-0.5816517-1.4500465-0.8736897c-0.0228157-0.0137978-0.051075-0.0431309-0.051506-0.0657177
		         c-0.0039635-0.207365-0.0023346-0.4148388-0.0023346-0.6365681c0.0399647,0.0223141,0.0689373,0.0372086,0.0966568,0.054163
		         c0.4694672,0.2871151,0.9398155,0.5728149,1.4070854,0.8634853c0.0757561,0.0471268,0.1360016,0.0456161,0.2090721,0.0021381
		         c1.3051662-0.7766266,2.6108608-1.5523624,3.9172974-2.3268356c0.064642-0.0383186,0.086874-0.0849056,0.0801945-0.1589737
		         c-0.0314808-0.3490543-0.1221447-0.4343662-0.4706211-0.4343662c-1.7076645-0.0000019-3.4153309,0-5.1229954,0
		         c-0.0358067,0-0.0716152,0-0.1120968,0c0-0.198307,0-0.3886089,0-0.5914688c0.0358047,0,0.071146,0,0.1064854,0
		         c1.5812607,0,3.1625214,0.0001087,4.743784-0.000123c0.2274914-0.0000343,0.4549923-0.0033169,0.682476-0.0022964
		         c0.455183,0.0020428,0.765255,0.3070259,0.7654743,0.7621946c0.0008659,1.8022041-0.000845,3.6044102-0.0027809,5.4066143
		         c-0.000267,0.2485447-0.0747681,0.4666862-0.2857418,0.6175461c-0.1530876,0.1094685-0.3305264,0.1327229-0.5127964,0.1327457
		         c-0.8397884,0.0001125-1.6795788,0.0000534-2.5193672,0.0000553c-0.9549446,0-1.9098892-0.0000019-2.8648338-0.0000019
		         C17.7783813,22.5662632,17.7425137,22.5662632,17.701746,22.5662632z"
      />
    </svg>
  ),
  displayName: 'CustomerHubIcon',
});
