import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const EyeSlashIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="8 8 16 16" className={classes.svg}>
      <path d="M22.562 13.346c.484.625.898 1.323 1.242 2.094.073.156.11.323.11.5s-.037.344-.11.5c-.38.849-.845 1.612-1.394 2.289a9.212 9.212 0 0 1-1.836 1.73 8.19 8.19 0 0 1-2.172 1.097 7.591 7.591 0 0 1-2.402.383c-.74 0-1.453-.102-2.141-.304a8.127 8.127 0 0 1-1.969-.883l-3.039 3.039a.414.414 0 0 1-.164.113.517.517 0 0 1-.376 0 .414.414 0 0 1-.164-.113c-.098-.099-.147-.216-.147-.352s.049-.253.148-.352l15-15c.099-.099.216-.148.352-.148s.253.05.352.148c.099.1.148.217.148.352a.48.48 0 0 1-.148.352l-2.906 2.914a9.483 9.483 0 0 1 1.616 1.641zM8.195 16.439a1.166 1.166 0 0 1-.109-.5c0-.177.036-.344.109-.5.38-.849.845-1.612 1.394-2.289a9.184 9.184 0 0 1 1.84-1.731 8.292 8.292 0 0 1 2.176-1.098A7.604 7.604 0 0 1 16 9.938c.562 0 1.109.058 1.641.176.531.117 1.049.29 1.555.519l-.767.767c-.39-.151-.787-.266-1.191-.344s-.816-.117-1.238-.117c-.724 0-1.427.113-2.109.34a7.208 7.208 0 0 0-1.918.977 8.09 8.09 0 0 0-1.625 1.547 9.354 9.354 0 0 0-1.238 2.043.217.217 0 0 0 0 .18 9.09 9.09 0 0 0 .848 1.519c.32.461.673.882 1.058 1.262l-.703.711c-.87-.855-1.576-1.88-2.118-3.079zm14.696-.406a.236.236 0 0 0 0-.188 9.09 9.09 0 0 0-1.156-1.933 8.432 8.432 0 0 0-1.508-1.496l-1.789 1.789c.177.25.315.52.414.808.099.29.148.598.148.926 0 .417-.078.806-.234 1.168A3.018 3.018 0 0 1 16 18.939a2.84 2.84 0 0 1-.926-.148 3.167 3.167 0 0 1-.808-.414l-1.641 1.648a7.09 7.09 0 0 0 1.629.68 6.53 6.53 0 0 0 1.746.234c.724 0 1.427-.113 2.109-.34a7.208 7.208 0 0 0 1.918-.977c.596-.424 1.138-.94 1.625-1.547s.9-1.287 1.239-2.042zM13 15.939c0-.411.078-.799.234-1.164a3.013 3.013 0 0 1 1.598-1.602A2.918 2.918 0 0 1 16 12.939c.26 0 .521.037.781.109l-.906.906a1.991 1.991 0 0 0-1.859 1.859l-.906.906a2.826 2.826 0 0 1-.11-.78zm5 0c0-.37-.094-.708-.281-1.016l-2.734 2.734c.307.188.646.281 1.016.281.276 0 .535-.052.777-.156A2.006 2.006 0 0 0 18 15.939z" />
    </svg>
  ),
  displayName: 'EyeSlashIcon',
});
