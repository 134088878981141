import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const AppsIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="8 8 18 16" className={classes.svg}>
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M 24.019 12.5 c 0 -0.4 -0.156 -0.777 -0.439 -1.061 L 20.561 8.42 c -0.584 -0.585 -1.537 -0.585 -2.121 0 l -2.543 2.543 C 15.68 10.401 15.138 10 14.5 10 h -4 C 9.673 10 9 10.672 9 11.5 V 16 v 5.5 c 0 0.827 0.673 1.5 1.5 1.5 H 16 h 4.5 c 0.827 0 1.5 -0.673 1.5 -1.5 v -4 c 0 -0.638 -0.402 -1.18 -0.964 -1.397 l 2.543 -2.543 C 23.862 13.277 24.019 12.9 24.019 12.5 Z M 16 14.139 L 17.86 16 H 16 V 14.139 Z M 10 11.5 c 0 -0.276 0.224 -0.5 0.5 -0.5 h 4 c 0.276 0 0.5 0.224 0.5 0.5 V 16 h -5 V 11.5 Z M 10.5 22 c -0.276 0 -0.5 -0.225 -0.5 -0.5 V 17 h 5 v 5 H 10.5 Z M 21 21.5 c 0 0.275 -0.225 0.5 -0.5 0.5 H 16 v -5 h 4.5 c 0.275 0 0.5 0.225 0.5 0.5 V 21.5 Z M 22.872 12.853 l -3.019 3.02 c -0.073 0.073 -0.164 0.109 -0.258 0.128 h -0.191 c -0.094 -0.018 -0.185 -0.055 -0.258 -0.128 l -3.019 -3.02 c -0.071 -0.071 -0.109 -0.162 -0.128 -0.259 v -0.188 c 0.019 -0.097 0.057 -0.188 0.128 -0.259 l 3.019 -3.019 c 0.098 -0.098 0.226 -0.146 0.354 -0.146 s 0.256 0.048 0.354 0.146 l 3.019 3.019 c 0.095 0.095 0.146 0.22 0.146 0.354 C 23.019 12.633 22.967 12.758 22.872 12.853 Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M 24.019 12.5 c 0 -0.4 -0.156 -0.777 -0.439 -1.061 L 20.561 8.42 c -0.584 -0.585 -1.537 -0.585 -2.121 0 l -2.543 2.543 C 15.68 10.401 15.138 10 14.5 10 h -4 C 9.673 10 9 10.672 9 11.5 V 16 v 5.5 c 0 0.827 0.673 1.5 1.5 1.5 H 16 h 4.5 c 0.827 0 1.5 -0.673 1.5 -1.5 v -4 c 0 -0.638 -0.402 -1.18 -0.964 -1.397 l 2.543 -2.543 C 23.862 13.277 24.019 12.9 24.019 12.5 Z M 16 14.139 L 17.86 16 H 16 V 14.139 Z M 10 11.5 c 0 -0.276 0.224 -0.5 0.5 -0.5 h 4 c 0.276 0 0.5 0.224 0.5 0.5 V 16 h -5 V 11.5 Z M 10.5 22 c -0.276 0 -0.5 -0.225 -0.5 -0.5 V 17 h 5 v 5 H 10.5 Z M 21 21.5 c 0 0.275 -0.225 0.5 -0.5 0.5 H 16 v -5 h 4.5 c 0.275 0 0.5 0.225 0.5 0.5 V 21.5 Z"
      />
    </svg>
  ),
  displayName: 'AppsIcon',
});
